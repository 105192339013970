@mixin ShippingMethod {
  .shipping-method-container {
    text-align: center;
    .shipping-method-card[data-selected='true'] {
      border: 1px solid map-get($foundation-palette, primary) !important;
    }
    .shipping-method-card {
      cursor: pointer;
      @include breakpoint(medium up) {
        width: rem-calc(480px);
        height: rem-calc(360px);
      }
      @include breakpoint(medium down) {
        margin: rem-calc(0px);
        margin-bottom: rem-calc(5px);
      }
      overflow: hidden;
      margin: rem-calc(20px);
      display: inline-block;
      width: rem-calc(340px);
      height: rem-calc(240px);
      border: 1px solid $interdark-gray;
      border-radius: rem-calc(20px);
      .image {
        background-color: $interdark-gray;
        height: calc(100% - 60px);
        background-size: cover;
      }
      .description {
        text-align: center;
        table {
          margin: auto;
        }
        padding: rem-calc(10px);
      }
      .icon {
        @include breakpoint(medium down) {
          display: none;
          visibility: hidden;
        }
        height: rem-calc(30px);
      }
    }
  }
}
