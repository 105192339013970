@mixin StoreMap {
  .shipping-store-map {
    display: inline-block;
    width: 100%;
    height: 100%;
    text-align: left;
    #maps-container {
      display: inline-block;
      height: calc(100% - 60px);
      width: 100%;
    }
    #maps-observer {
      float: left;
      height: 100%;
      width: 100%;
      transition: width 0.3s;
      @include breakpoint(medium down) {
        width: 100%;
        display: inline-block;
      }
    }
    #maps-marker-summary {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      width: 0%;
      float: left;
      transition: width 0.3s;
      @include breakpoint(medium down) {
        width: 100%;
        display: none;
        overflow-x: none;
        overflow-y: none;
        height: auto;
      }
    }
    &.opened {
      @include breakpoint(medium down) {
        height: auto;
      }
      #maps-observer {
        height: 100%;
        width: 80%;
        @include breakpoint(medium down) {
          width: 100%;
          display: none;
        }
      }
      #maps-marker-summary {
        width: 20%;
        @include breakpoint(medium down) {
          width: 100%;
          display: inline-block;
        }
      }
    }
    .markerCard {
      .title {
        font-weight: bold;
        color: map-get($foundation-palette, primary);
      }
      .phs-button {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: rem-calc(10px) !important;
        margin-bottom: rem-calc(10px) !important;
      }
      .button-container {
        display: inline-block;
        text-align: center;
        align-content: center;
        width: 100%;
      }
      &.selected {
        border: 1px solid map-get($foundation-palette, primary);
      }
      padding-left: rem-calc(10px);
      padding-top: rem-calc(10px);
      margin-bottom: rem-calc(-1px);
      width: 100%;
      height: auto;
      border: 1px solid $interdark-gray;
    }
    #autocomplete-places {
      display: inline-block;
      margin-bottom: rem-calc(10px) !important;
      border: 1px solid $interdark-gray;
      padding: rem-calc(10px);
      min-width: rem-calc(200px);
      margin-bottom: rem-calc(30px);
      width: 50%;
      @include breakpoint(large up) {
        margin-left: rem-calc(10px);
      }
      @include breakpoint(medium down) {
        background-color: $light-gray;
        border-radius: rem-calc(50px);
        width: 94%;
        margin: 3%;
      }
    }
    #maps-search-button {
      @include breakpoint(medium down) {
        display: none;
      }
      margin-left: rem-calc(10px);
      background-color: map-get($foundation-palette, primary);
      border-radius: rem-calc(10px);
      color: $white;
      padding: rem-calc(10px);
      padding-right: rem-calc(30px);
      img {
        height: rem-calc(30px);
        padding: 0px;
        margin: rem-calc(-10px);
        margin-right: rem-calc(-20px);
        margin-left: rem-calc(20px);
      }
    }
    .distance_label {
      float: right;
      padding-top: rem-calc(10px);
      padding-right: rem-calc(10px);
    }
    .store-map-search-area {
      display: inline;
    }
    .legend {
      height: rem-calc(60px);
      @include breakpoint(medium down) {
        display: none;
      }
      float: right;
      .shop_icon {
        padding: rem-calc(10px);
        float: left;
        height: 100%;
        img {
          padding: rem-calc(5px);
          height: rem-calc(30px);
        }
      }
    }
    &.opened {
      #switch_between_map_and_shop {
        .shop {
          color: $white;
          background-color: map-get($foundation-palette, secondary);
        }
        .maps {
          color: map-get($foundation-palette, secondary);
          background-color: $white;
        }
      }
    }
    #switch_between_map_and_shop {
      display: grid;
      grid-auto-rows: rem-calc(50px);
      grid-template-columns: repeat(2, 50%);
      margin-bottom: rem-calc(10px);
      .shop {
        font-weight: bold;
        border: 1px solid map-get($foundation-palette, secondary);
        border-radius: rem-calc(50px) 0px 0px rem-calc(50px);
        color: map-get($foundation-palette, secondary);
        background-color: $white;
        padding: rem-calc(15px);
      }
      .maps {
        font-weight: bold;
        border: 1px solid map-get($foundation-palette, secondary);
        border-radius: 0px rem-calc(50px) rem-calc(50px) 0px;
        color: $white;
        background-color: map-get($foundation-palette, secondary);
        padding: rem-calc(15px);
      }
    }
    .title {
      font-weight: bold;
      color: map-get($foundation-palette, primary);
    }
  }
  .store-not-found-near-you {
    margin: auto;
  }
}
