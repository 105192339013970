@import '../phs-config';

@mixin phs-loader {
  #loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    background: rgba(0, 0, 0, 0.7);

    & .heart-container {
      position: absolute;
      top: 50%;
      left: 50%;
      width: rem-calc(100px);
      height: rem-calc(100px);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: 32px 32px;

      & .heart-content {
        top: rem-calc(54px);
        left: 0;
        position: absolute;
        width: rem-calc(54px);
        height: rem-calc(54px);
        background: map-get($foundation-palette, primary);
        animation: pulsing-heart 2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);

        &::before,
        &::after {
          content: '';
          position: absolute;
          display: block;
          width: rem-calc(54px);
          height: rem-calc(54px);
          background: map-get($foundation-palette, primary);
        }

        &::before {
          left: rem-calc(-30px);
          border-radius: 50% 0 0 50%;
        }

        &::after {
          top: rem-calc(-30px);
          border-radius: 50% 50% 0 0;
        }
      }
    }

    & div {
      position: absolute;
      top: 55%;
      left: 50%;
      transform: translate(-55%, -50%);

      & p {
        text-align: center;
        color: $white;
        font-weight: bold;
      }
    }
  }
}
