// Importing Normalize and dependencies of Foundation
@import 'node_modules/foundation-sites/scss/vendor/normalize';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/missing-dependencies';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/helpers/true';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/contain';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/purge';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/remove';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/replace';
@import 'node_modules/foundation-sites/_vendor/sassy-lists/stylesheets/functions/to-list';

@import 'node_modules/foundation-sites/scss/global';
@import './utils/_lubalin.scss';

// Importing Global PhotSi Config
@import './phs-config';

@include add-foundation-colors;

// Importing modules and components
@import 'node_modules/foundation-sites/scss/grid/grid';
@import 'node_modules/foundation-sites/scss/xy-grid/xy-grid';
@import 'node_modules/foundation-sites/scss/components/flex';

// Importing PhotoSi Components overwrites
@import './components/menu';
@import './components/footer';
@import './components/grid';
@import './components/buttons';
@import './components/loader';
@import './utils/borders';
@import './utils/flex';
@import './utils/links';
@import './utils/rounded';
@import './utils/sizing';
@import './utils/spacing';
@import './utils/typography';
@import './utils/utils';
@import './utils/visibility';

@include foundation-global-styles;

// Grid
@include flex-helpers;
@include foundation-xy-grid-classes;
@include makeGrid;

// Components
@include phs-menu;
@include phs-flex-classes;
@include phs-footer;
@include phs-buttons;
@include phs-loader;
@include phs-display-flex;

// Helpers
@include phs-width-classes;
@include phs-spacing;
@include phs-text-alignment;
@include phs-text-transformation;
@include phs-hr;
@include phs-background-utils;
@include phs-colors-utils;
@include phs-overflow-hidden;
@include phs-font-weights;
@include phs-border-utils;
@include phs-rounded;
@include phs-visbility-classes;
@include phs-minmax-height-classes;
@include phs-links;

// SHOPPING CART SPECIFICS
@import './shopping-cart/shopping-cart-consumer';
@include phs-storelocator;
