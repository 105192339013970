@mixin RoundedRadio {
  .rounded-radio {
    border: 1px solid map-get($foundation-palette, secondary);
    border-radius: rem-calc(100px);
    height: rem-calc(30px);
    width: rem-calc(30px);
    .internal-circle {
      margin: auto;
      margin-top: rem-calc(4px);
      border-radius: rem-calc(100px);
      height: rem-calc(20px);
      width: rem-calc(20px);
      background-color: transparent;
      &.checked {
        background-color: map-get($foundation-palette, secondary);
      }
    }
  }
}
