@mixin Modal {
  .modal-container {
    &.opened {
      display: flex;
    }
    select {
      padding: rem-calc(10px);
      border-radius: rem-calc(3px);
      @include breakpoint(medium down) {
        width: 90% !important;
        margin: auto !important;
      }
    }
    display: none;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1001;
    &.conteinerized {
      position: absolute !important;
      @include breakpoint(medium down) {
        position: absolute !important;
      }
      text-align: center;
      .dialog {
        margin: auto;
        width: 100%;
        max-width: rem-calc(340px);
        height: auto;
        max-height: rem-calc(340px);
      }
    }
    .dialog {
      .close-button {
        position: relative;
        text-align: center;
        border-radius: rem-calc(50px);
        height: rem-calc(30px);
        width: rem-calc(30px);
        background-color: $black;
        color: $white;
        float: right;
        margin-bottom: rem-calc(-30px);
        margin-top: rem-calc(15px);
        margin-right: rem-calc(15px);
        font-size: rem-calc(20px);
        padding: rem-calc(4px);
        z-index: 10;
        top: rem-calc(-20px);
        right: rem-calc(-20px);
        cursor: pointer;
      }
      @include breakpoint(medium down) {
        margin: rem-calc(40px);
        width: 100%;
        height: auto;
      }
      padding: rem-calc(20px);
      width: 70%;
      height: 60%;
      margin: auto;
      border-radius: rem-calc(20px);
      background-color: $white;
    }
    .ShopModalCard {
      text-align: left;
      position: relative;
      z-index: 1;
      display: inline-block;
      height: 100%;
      width: 100%;
      padding: rem-calc(10px);
      .distance_label {
        float: right;
        padding-top: rem-calc(0px);
        padding-right: rem-calc(0px);
      }
      .title {
        color: map-get($foundation-palette, primary);
        font-weight: bold;
      }
      .top-container {
        margin-bottom: rem-calc(30px);
      }
      .bottom-container {
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
      }
      .contact-container {
        display: grid;
        grid-auto-rows: auto;
        grid-template-columns: repeat(2, 50%);
        margin-bottom: rem-calc(20px);
        a {
          text-decoration: none;
          color: $black;
          font-size: rem-calc(10px);
          margin: rem-calc(5px);
        }
        .round-icon {
          border-radius: rem-calc(100px);
          height: rem-calc(50px);
          width: rem-calc(50px);
          background-color: map-get($foundation-palette, secondary);
          background-size: rem-calc(30px) auto;
          background-repeat: no-repeat;
          background-position: center;
          margin-left: auto;
          margin-right: auto;
          margin-bottom: rem-calc(5px);
          img {
            width: rem-calc(50px);
          }
        }
      }
    }
    .component-area {
      width: 100%;
      height: calc(100% - 30px);
      position: relative;
      display: flex;
      vertical-align: middle;
      .circle-preview-thumbnail {
        margin: auto !important;
        margin-bottom: rem-calc(30px) !important;
      }
      .modal-confirm-delete-product-row {
        margin-left: auto;
        margin-right: auto;
        margin-top: rem-calc(20px);
      }
    }
    .confirm-button-container {
      width: 100%;
      position: absolute;
      bottom: 0px;
      height: rem-calc(70px);
      margin-right: auto;
      margin-left: auto;
      button {
        margin-left: rem-calc(10px) !important;
        margin-right: rem-calc(10px) !important;
      }
    }
    .acceptedConditionIframe {
      border: rem-calc(0px);
      width: 100%;
      height: 100%;
    }
  }
}
