@mixin OrderSuccess {
  #order-success {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, 50%);
    @include breakpoint(medium down) {
      grid-template-columns: repeat(1, 100%);
    }
    .success_logo {
      max-height: rem-calc(500px);
      @include breakpoint(medium down) {
        max-height: rem-calc(240px);
      }
    }
    #left-column {
    }
    #right-column {
      h2.top-title {
        font-weight: normal;
        margin-top: rem-calc(10px);
        margin-bottom: rem-calc(10px);
      }
      h1 {
        margin-top: rem-calc(10px);
        margin-bottom: rem-calc(10px);
      }
      display: grid;
      div.sub-title {
        font-weight: normal;
      }
    }
    button {
      margin-top: rem-calc(10px) !important;
      width: rem-calc(350px);
    }

    .button-container {
      padding-top: rem-calc(10px);
      padding-bottom: rem-calc(10px);
      .hollow {
        background-color: $white;
      }
      @include breakpoint(medium down) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
        background-color: $light-gray;
      }
    }
  }
}
