@mixin tbodySizeDefault {
  tbody tr {
    @include breakpoint(medium up) {
      td {
        padding: rem-calc(5px);
        &:nth-child(1) {
          width: rem-calc(60px);
        }
        &:nth-child(2) {
          width: rem-calc(300px);
          text-align: left;
        }
        &:nth-child(3) {
          width: rem-calc(80px);
          text-align: center;
        }
        &:nth-child(4) {
          width: rem-calc(80px);
          text-align: center;
        }
        &:nth-child(5) {
          width: rem-calc(80px);
          text-align: center;
        }
        &:nth-child(6) {
          width: rem-calc(90px);
          text-align: center;
        }
      }
    }
  }
}

@mixin ShoppingCartSummary {
  #total-preview {
    font-size: rem-calc(13px);
    font-weight: bold;
  }
  .proceed-button {
    text-align: center;
    @include breakpoint(medium down) {
      text-align: left;
      .total-price {
        float: right;
        padding: 0px;
        margin: 0px !important;
        vertical-align: middle;
      }
    }
  }
  @include breakpoint(medium down) {
    padding: 0px;
  }
  .image-icon {
    cursor: pointer;
    width: auto;
    height: rem-calc(16px);
    margin-left: rem-calc(10px);
    margin-top: rem-calc(-5px);
  }
  #cart-header {
    @include breakpoint(medium down) {
      margin: rem-calc(20px);
    }
    margin: rem-calc(60px);
  }
  #cart-progress {
    max-width: rem-calc(370px);
    @include breakpoint(medium down) {
      display: none;
      visibility: hidden;
    }
    width: fit-content;
    margin: auto;
  }
  #cart-progress-label {
    margin: auto;
    max-width: rem-calc(430px);
    @include breakpoint(medium down) {
      display: none;
      visibility: hidden;
    }
    width: fit-content;
    margin: auto;
    margin-top: rem-calc(10px);
    margin-bottom: rem-calc(10px);
    .label {
      text-transform: capitalize;
      width: rem-calc(110px);
      color: $interdark-gray;
    }
    .label.active {
      color: map-get($foundation-palette, secondary) !important;
    }
    .label.old {
      color: $dark-gray;
    }
  }
  .phs-button {
    width: auto;
  }
  font-family: $body-font-family;
  padding: rem-calc(50px);
  .orange-circle {
    border-radius: rem-calc(200px);
    height: rem-calc(40px);
    width: rem-calc(40px);
    float: left;
    background: map-get($foundation-palette, primary);
    padding: rem-calc(6px);
    text-align: center;
    color: $white;
    font-size: rem-calc(24px);
    margin-right: rem-calc(20px);
    margin-bottom: rem-calc(30px);
  }
  .green-circle-container {
    text-align: center;
    width: rem-calc(30px);
    .old {
      background: $dark-gray !important;
    }
    .green-circle {
      border-radius: rem-calc(200px);
      height: rem-calc(30px);
      width: rem-calc(30px);
      float: left;
      background: $interdark-gray;
      padding: rem-calc(4px);
      text-align: center;
      color: $white;
      font-size: rem-calc(20px);
    }
    .green-circle.active {
      background: map-get($foundation-palette, secondary) !important;
    }
  }
  .circle-separator {
    width: rem-calc(80px);
    margin: auto;
    hr {
      border-top: 1px solid $interdark-gray;
      border-bottom: 0px none transparent;
      width: 100%;
    }
  }
  .line-separator {
    border: none;
    border-top: 1px solid $interdark-gray;
  }
  .uc {
    text-transform: uppercase;
  }
  ul.ltn {
    list-style-type: none;
  }
  .cart-grid {
    display: grid;
    grid-auto-rows: 100%;
    grid-template-columns: repeat(2, 50%);
    @include breakpoint(medium down) {
      grid-template-columns: repeat(1, 100%);
    }
    #empty-cart-left-column {
      border-right: 1px solid $light-gray;
      @include breakpoint(medium down) {
        display: none;
        visibility: hidden;
        border: 0px;
      }
    }
    #empty-cart-right-column {
      border-left: 1px solid $light-gray;
      @include breakpoint(medium down) {
        border: 0px;
      }
      .content {
        margin: auto;
      }
    }
  }
  .sad-cart-image {
    width: rem-calc(260px);
  }
  .medium-typo {
    p {
      font-size: rem-calc(16px);
    }

    h5.no-pad {
      padding: 0px;
    }
    h5.no-mar {
      margin: 0px;
    }
  }
  #empty-cart-list {
    margin-left: 0px;
    padding-left: 0px;
    li {
      display: flex;
      margin-left: 0px;
      margin-bottom: rem-calc(20px);
    }
  }
  #order-items-list {
    width: 100%;
  }
  .circle-preview-thumbnail {
    position: relative;
    border-radius: rem-calc(20px);
    height: rem-calc(60px);
    width: rem-calc(60px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .badge-giftbox {
      position: absolute;
      width: 60%;
      right: rem-calc(-5px);
      bottom: rem-calc(-3px);
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        img {
          max-height: rem-calc(35px);
        }
      }
    }
  }
  .order-shipping-method {
    background-color: $light-gray;
    @include tbodySizeDefault;
  }
  .order-shipping-price {
    @include tbodySizeDefault;
  }
  .order-shipping-method,
  .order-coupon,
  .order-shipping-price,
  .order-items-table {
    .remove-item,
    .edit-item {
      cursor: pointer;
      display: flex;
    }
    @include tbodySizeDefault;
    .sub-row {
      padding: rem-calc(5px);
      display: grid;
      grid-auto-rows: 100%;
      grid-template-columns: repeat(3, auto);
      div {
        padding: rem-calc(10px);
      }
      .price-row {
        margin: auto;
      }
      .remove-item{
        float: right;
      }
    }
    width: 100%;
    margin-bottom: rem-calc(20px);
    .divider {
      border-top: 1px solid $interdark-gray;
      height: 1px;
      margin: 0px;
      padding: 0px;
    }
    border-collapse: separate;
    border: 1px solid $interdark-gray;
    border-radius: rem-calc(24px);
    thead {
      color: $interdark-gray;
      tr {
        th {
          height: rem-calc(40px);
          padding: rem-calc(5px);
        }
      }
    }
    @include breakpoint(medium down) {
      tbody:not(:last-child):after {
        content: '';
        display: block;
        position: relative;
        left: -2px;
        height: 1px;
        width: calc(100% + 4px);
        border-top: 1px solid $interdark-gray;
      }
    }
    .text-left {
      text-align: left;
    }
  }
  #order-summary {
    @include breakpoint(medium down) {
      position: fixed;
      bottom: 0px;
      left: 0px;
      border-radius: 0 0 0 0;
      margin: 0px;
      width: 100%;
      z-index: 100;
    }
    .total-price {
      margin-top: rem-calc(10px);
      margin-bottom: rem-calc(20px);
    }
    .promcode-text-container {
      display: grid;
      grid-template-columns: repeat(2, auto);
      margin-top: rem-calc(10px);
      margin-bottom: rem-calc(30px);
      .promocode-text {
        margin-left: rem-calc(5px);
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    button {
      width: 100%;
      @include breakpoint(medium up) {
        margin-bottom: rem-calc(10px) !important;
      }
    }
    height: fit-content;
    @include breakpoint(medium down) {
      height: auto;
    }
    margin-left: rem-calc(20px);
    padding: rem-calc(15px);
    width: rem-calc(600px);
    border-radius: rem-calc(24px);
    background-color: $light-gray;
  }
  .acceptConditionContainer {
    height: rem-calc(30px);
    margin-bottom: rem-calc(20px);
    .acceptConditionCheckbox {
      display: inline-block;
      float: left;
      width: rem-calc(20px);
      height: rem-calc(20px);
      margin: rem-calc(0px);
      margin-top: rem-calc(3px);
      margin-right: rem-calc(5px);
    }
    .acceptConditionLabel {
      line-height: rem-calc(30px);
    }
    a {
      cursor: pointer;
      margin: rem-calc(5px);
    }
    @include breakpoint(medium down) {
      text-align: center;
    }
  }
}
