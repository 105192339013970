@mixin GenericForm {
  form {
    width: 100%;
    max-width: rem-calc(400px);
    margin: auto;
    .button-area {
      @include breakpoint(medium down) {
        display: flex;
        flex-flow: column;
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
        background-color: $light-gray;
        padding-top: rem-calc(20px);
        padding-left: rem-calc(20px);
        padding-right: rem-calc(20px);
        button {
          margin: auto !important;
          margin-bottom: rem-calc(15px) !important;
          width: 100% !important;
        }
        button[name='submit'] {
          order: 0;
        }
        label {
          order: 1;
        }
      }
      button {
        margin-left: rem-calc(5px) !important;
        margin-right: rem-calc(5px) !important;
      }
      margin-top: rem-calc(10px);
      margin-bottom: rem-calc(10px);
    }
  }
  textarea,
  input,
  select {
    width: 100%;
    padding: rem-calc(10px);
    margin-top: rem-calc(10px);
    border: 1px solid $interdark-gray;
    border-radius: rem-calc(3px);
    display: flex;
  }
  textarea {
    height: rem-calc(170px);
  }
  .error {
    border-color: red !important;
  }
  .valid {
    border-color: map-get($foundation-palette, primary);
  }
  button[disabled] {
    background-color: $interdark-gray !important;
    pointer-events: none;
  }
}
