@mixin CouponCodeComponent {
  #coupon-code-input-container {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, auto);
    @include breakpoint(medium down) {
      display: flex;
    }
    #input-area {
      width: rem-calc(298px);
    }
    #button-area {
      display: contents;
      width: rem-calc(100px);
      margin: rem-calc(0px);
    }
    button[name='reset-coupon-code'] {
      border-radius: rem-calc(50px);
      background-color: map-get($foundation-palette, secondary);
      color: $white;
      padding: rem-calc(5px);
      width: rem-calc(24px);
      margin-left: rem-calc(-28px);
      cursor: pointer;
    }

    input[name='coupon-code'] {
      @include breakpoint(medium down) {
        border-radius: rem-calc(50px) 0 0 rem-calc(50px);
      }
      display: inline-block;
      width: 100%;
      border: 1px solid $black;
      border-radius: rem-calc(0px);
      padding: rem-calc(10px);
      margin-top: 0px;
      margin-bottom: rem-calc(20px);
      height: rem-calc(40px);
    }
    input[name='coupon-code'].isCurrentCoupon {
      color: map-get($foundation-palette, primary);
      border-color: $interdark-gray;
      pointer-events: none;
    }
    input[name='coupon-code']:focus {
      border: 1px solid map-get($foundation-palette, secondary);
    }
    button[name='apply-coupon-code'] {
      @include breakpoint(medium down) {
        border-radius: 0 rem-calc(50px) rem-calc(50px) 0;
      }
      background-color: map-get($foundation-palette, secondary);
      color: $white;
      padding: rem-calc(10px);
      width: rem-calc(80px);
      height: rem-calc(40px);
    }
  }
  #coupon-code-input-container.noCouponCode {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(1, auto);
    input[name='coupon-code'] {
      @include breakpoint(medium down) {
        border-radius: rem-calc(50px) rem-calc(50px) rem-calc(50px) rem-calc(50px);
      }
    }
    button[name='reset-coupon-code'] {
      display: none;
    }
    button[name='apply-coupon-code'] {
      display: none;
    }
    #input-area {
      width: 100%;
    }
    #button-area {
      display: none;
    }
  }
  .accepted-coupon {
    margin: auto;
  }
  .denied-coupon {
    margin: auto;
  }
}
