@mixin ShareWithFriends {
  #share-with-friends {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: repeat(2, 50%);
    @include breakpoint(medium down) {
      grid-template-columns: repeat(1, 100%);
    }
    #left-column {
      .image-logo {
        height: rem-calc(500px);
      }
    }
    #right-column {
      margin-top: auto;
      display: grid;
      .top-title {
        margin-top: auto;
      }
      div.sub-title {
        font-weight: normal;
      }
      .gift-code-container {
        text-align: center;
        padding: rem-calc(10px);
        margin-bottom: rem-calc(30px);
        .gift-code {
          border-radius: rem-calc(20px);
          margin: auto;
          border: 1px dashed $interdark-gray;
          padding: rem-calc(15px);
          width: rem-calc(270px);
          .code {
            margin: rem-calc(20px);
            color: map-get($foundation-palette, primary);
          }
        }
      }
      @include breakpoint(medium down) {
        .image-logo {
          max-height: rem-calc(300px);
        }
      }
    }
    .button-container {
      padding-top: rem-calc(10px);
      padding-bottom: rem-calc(10px);
      .hollow {
        background-color: $white;
      }
      @include breakpoint(medium down) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
        background-color: $light-gray;
      }
    }
  }
}
