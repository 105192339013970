@mixin EditProductQuantity {
  .editProductQuantity {
    text-align: center;
    margin: auto;
    padding: rem-calc(0px) !important;
    max-width: rem-calc(80px);
    .circle {
      background-color: map-get($foundation-palette, secondary);
      color: $white;
      border-radius: rem-calc(100px);
      height: rem-calc(24px);
      width: rem-calc(32px);
      text-align: center;
      line-height: rem-calc(5px);
      margin: rem-calc(0px);
      padding: rem-calc(0px);
      cursor: pointer;
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      margin: auto;
      width: rem-calc(40px);
      text-align: center;
      padding: rem-calc(0px);
      border: 0px none transparent;
      -moz-appearance: textfield;
      -webkit-appearance: none;
    }
  }
}
