@import './generic-form.scss';

@mixin ShippingMailOrder {
  .shipping-mail-order-top-level {
    margin: rem-calc(30px);
    @include breakpoint(medium down) {
      margin: rem-calc(10px);
    }
    .mail-order-address-container {
      margin: auto;
      @include breakpoint(large up) {
        display: flex;
      }
    }
    .button-area {
      @include breakpoint(medium down) {
        display: flex;
        flex-flow: column;
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
        background-color: $light-gray;
        padding-top: rem-calc(20px);
        padding-left: rem-calc(20px);
        padding-right: rem-calc(20px);
        button {
          margin: auto !important;
          margin-bottom: rem-calc(15px) !important;
          width: 100% !important;
        }
        #continue {
          order: 0;
        }
        #add {
          order: 1;
        }
      }
      margin-top: rem-calc(10px);
      margin-bottom: rem-calc(10px);
    }
    #top-area {
      @include breakpoint(medium down) {
        position: fixed;
        bottom: 0px;
        left: 0px;
        border-radius: 0 0 0 0;
        margin: 0px;
        width: 100%;
        z-index: 100;
      }
      button {
        width: 100%;
        @include breakpoint(medium up) {
          margin-bottom: rem-calc(10px) !important;
        }
      }
      height: fit-content;
      @include breakpoint(medium down) {
        height: auto;
      }
      margin-left: rem-calc(20px);
      padding: rem-calc(15px);
      width: rem-calc(300px);
      border-radius: rem-calc(24px);
      background-color: $light-gray;
    }
  }
  .shipping-mail-order-address-edit {
    @include GenericForm;
    text-align: center;
    textarea,
    input,
    select {
      width: 100%;
      padding: rem-calc(10px);
      margin-top: rem-calc(10px);
      border: 1px solid $interdark-gray;
      border-radius: rem-calc(3px);
      display: flex;
    }
    textarea {
      height: rem-calc(170px);
    }
    .error {
      border-color: red !important;
    }
    .valid {
      border-color: map-get($foundation-palette, primary);
    }
    button[disabled] {
      background-color: $interdark-gray !important;
      pointer-events: none;
    }
  }
  .shipping-mail-order {
    text-align: center;
    .card-container {
      display: grid !important;
      grid-auto-rows: rem-calc(200px);
      grid-template-columns: repeat(2, 50%);
      @include breakpoint(medium down) {
        grid-template-columns: repeat(1, 100%);
      }
    }
    .no-address-warning {
      display: block !important;
      margin-left: auto;
      margin-right: auto;
      margin-top: rem-calc(20px);
      margin-bottom: rem-calc(40px);
    }
    .shipping-address-item.selected {
      border: 2px solid map-get($foundation-palette, secondary);
      position: relative;
      top: 0px;
      left: 0px;
      .editing {
        border-top: 2px solid map-get($foundation-palette, secondary);
      }
      .selcted_address_label {
        display: inline-block;
      }
    }
    .shipping-address-item {
      position: relative;
      top: 1px;
      left: 1px;
      @include breakpoint(medium down) {
        margin: auto;
      }
      .rounded-radio {
        float: right;
      }
      text-align: left;
      border: 1px solid $interdark-gray;
      border-radius: rem-calc(20px);
      width: rem-calc(300px);
      height: rem-calc(170px);
      margin: rem-calc(10px);
      .address {
        padding: rem-calc(10px);
        height: rem-calc(130px);
      }
      .editing {
        border-top: 1px solid $interdark-gray;
        table {
          width: 100%;
          td {
            a {
              display: block;
            }
            padding-top: rem-calc(5px);
            padding-bottom: rem-calc(10px);
            padding-left: rem-calc(10px);
            padding-right: rem-calc(10px);
            width: 50%;
            &:first-child {
              text-align: left;
            }
            &:last-child {
              text-align: right;
            }
          }
        }
        .edit-item {
          float: left;
          cursor: pointer !important;
          margin-left: auto;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            img {
              padding-left: rem-calc(10px);
            }
          }
        }
        .remove-item {
          float: right;
          cursor: pointer !important;
          margin-left: auto;
          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            img {
              padding-left: rem-calc(10px);
            }
          }
        }
      }
      .selcted_address_label {
        display: none;
      }
    }
  }
}
