// SHOPPING CART SPECIFICS
@import './summary.scss';
@import './shipping-method.scss';
@import './shipping-mail-order.scss';
@import './rounded-radio.scss';
@import './shipping-store-map.scss';
@import './shopping-cart-order-success.scss';
@import './shopping-cart-share-with-friens.scss';
@import './modal.scss';
@import './share-coupon.scss';
@import './coupon-code-component.scss';
@import './choose-user-type.scss';
@import './retailer-shipping.scss';
@import './payment-list.scss';
@import './error404.scss';
@import './edit-product-quantity.scss';
@import './userprofile-incomplete.scss';
@import './shopping-cart-empty.scss';
@import './error-generic.scss';

@mixin phs-storelocator {
  body,
  html {
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
  }
  #store-locator-container {
    width: 100%;
    height: 100%;
    .first-logo-container {
      display: flex;
      width: 100%;
    }
    #first-logo {
      margin: auto;
    }
    input:disabled {
      pointer-events: none;
    }
    label.error {
      color: map-get($foundation-palette, primary);
    }
    padding: 0px !important;
    margin-bottom: rem-calc(20px);
    select {
      padding: rem-calc(10px);
      border-radius: rem-calc(3px);
    }
    .historyback {
      margin-bottom: rem-calc(10px) !important;
    }
    .link-localized-label {
      cursor: pointer;
    }
    @include ShoppingCartSummary;
    @include ShippingMethod;
    @include ShippingMailOrder;
    @include RoundedRadio;
    @include StoreMap;
    @include OrderSuccess;
    @include ShareWithFriends;
    @include Modal;
    @include ShareCoupon;
    @include CouponCodeComponent;
    @include ChooseUserType;
    @include RetailerShipping;
    @include PaymentList;
    @include Error404;
    @include EditProductQuantity;
    @include UserProfileIncomplete;
    @include EmptyCart;
    @include ErrorGeneric;
  }
}
