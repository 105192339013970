$defaultRadius: 20px;

@mixin PaymentList {
  .payment-method-list.all-round {
    margin-top: rem-calc(20px);
    li {
      cursor: pointer;
      border-radius: rem-calc($defaultRadius) rem-calc($defaultRadius) rem-calc($defaultRadius) rem-calc($defaultRadius) !important;
      margin-bottom: rem-calc(10px);
    }
  }
  .payment-method-list {
    button {
      cursor: pointer;
    }
    list-style-type: none;
    margin: auto;
    padding: 0px;
    max-width: rem-calc(300px);
    .pay-method {
      button {
        width: 100%;
        margin: auto;
      }
      display: flex;
      margin-top: -1px;
      border: 1px solid $interdark-gray;
      padding: rem-calc(10px);
      height: rem-calc(60px);
      img {
        height: rem-calc(40px);
      }
    }
    li {
      &:first-child {
        border-radius: rem-calc($defaultRadius) rem-calc($defaultRadius) 0 0;
      }
      &:last-child {
        border-radius: 0 0 rem-calc($defaultRadius) rem-calc($defaultRadius);
      }
    }
  }
}
